<template>
  <div
    ref="refSpeechIndex"
    class="speech-index"
    :style=" { 'background-size': '100% 40%',
               'background-image': 'url(' + require('@/assets/background.png') + ')'}" >
    <SearchInput
      ref="refSearchInput"
      :addInputText="addInputText"
      :defaultKeyword="keyword"
      @search="parseKeyword"
      @reset="reset"
      @searchItem="searchItem" />
    <div class="scroller-wrapper" ref="refScrollerWrapper" :style="{height: `calc(100vh - 60px - ${moreHeight}px)`}">
      <div v-show="showSearchResult" class="search-result-wrapper">
        <scroller
          ref="my_scroller"
        >
          <div ref="refScrollerContent" :style="{minHeight: `calc(100vh - 60px - ${moreHeight}px)`}">
            <div class="speech-view-box" v-for="item in tabList" :key="item['key']">
              <van-cell v-if="item['key'] !== 'speech'" :title="item['tab']" is-link arrow-direction="down" @click="item['showContent'] = !item['showContent']"/>
              <div>
                <template v-if="item['key'] === 'speech'">
                  <SearchSpeech
                    ref="refSearchSpeech"
                    :active="item['showContent']"
                    :searchId="keywordSearchHistoryId"
                    @showPage="showPage"
                    @result="onResult(arguments, 'speech')"
                    @getChartData="getChartData"
                    @changeSearchWord="changeSearchWord"
                    @ifShowContent="item['showContent'] = !item['showContent']"/>
                </template>
                <template v-if="item['key'] === 'document'">
                  <search-document
                    key="document"
                    :keyword="searchKeyWord"
                    :active="item['showContent']"
                    index-name="wdss2"
                    @result="onResult(arguments, 'document')" />
                </template>
                <template v-if="item['key'] === 'interaction'">
                  <search-interaction
                    key="interaction"
                    :keyword="searchKeyWord"
                    :active="item['showContent']"
                    @result="onResult(arguments, 'interaction')" />
                </template>
                <template v-if="item['key'] === 'web'">
                  <search-document
                    key="web"
                    :keyword="searchKeyWord"
                    :active="item['showContent']"
                    index-name="webinfo"
                    @result="onResult(arguments, 'web')" />
                </template>
                <!-- </div> -->
              </div>
            </div>
            <template v-if="showSearchResult">
              <div class="speech-view-box" v-if="keywordMatched">
                <SpeechRecommentWord :keyword="keywordMatched.keyword" :keywordMatchedList="keywordMatched.keywordList" @recommentActiveWord="getRecommentActiveWord" @FilterParseKeyword="FilterParseKeyword"/>
              </div>
              <div class="speech-view-box">
                <SearchComment ref="searchComment" :searchId="keywordSearchHistoryId" />
              </div>
            </template>
          </div>
        </scroller>
        <div class="left-arrow" @click="clickLeftArrow" v-show="!!historyLocations[activeDataRegion.id]"><van-icon name="arrow-left" /></div>
        <div class="right-arrow" @click="clickRightArrow" v-show="searchHistories[activeDataRegion.id] && (historyLocations[activeDataRegion.id] !== searchHistories[activeDataRegion.id].length - 1)"><van-icon name="arrow"/></div>
        <RecommentsChooseDialog
          v-if="keywordMatched"
          ref="RecommentChooseDialog"
          @submit="FilterParseKeyword"
          :recommentActiveWord="recommentActiveWord"
          :dataRegionId="activeDataRegion.id"
          :keywordMatched="keywordMatched"
          :reportData="originReportData"
          :ignoreImplicit="ignoreImplicit"/>
      </div>
    </div>
    <WxShareTips ref="refWxShareTips" />
    <DocumentPreview ref="refDocumentPreview" />
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import SearchComment from '@/components/speech/SearchComment'
import SpeechRecommentWord from '@/components/speech/SpeechRecommentWord'
import RecommentsChooseDialog from '@/components/speech/RecommentsChooseDialog'
import SearchInput from '@/components/speech/SearchInput'
import WxShareTips from '@/components/global/WxShareTips'
import SearchDocument from '@/components/search/SearchDocument'
import SearchInteraction from '@/components/search/SearchInteraction'
import DocumentPreview from '@/components/search/DocumentPreview'
import SearchSpeech from '@/components/search/SearchSpeech'

const SEARCH_STATUS = {
  WAITING: 0,
  INPUTTING: 1,
  PARSING: 2,
  REPORTING: 3,
  RENDERING: 4
}
export default {
  name: 'PublicChart',
  components: {
    SearchInput,
    SearchComment,
    SpeechRecommentWord,
    RecommentsChooseDialog,
    WxShareTips,
    SearchDocument,
    SearchInteraction,
    DocumentPreview,
    SearchSpeech
  },
  data() {
    return {
      isRefresh: false,
      SEARCH_STATUS,
      keywordInputFocus: false,
      hasSearchResult: false,
      keywordMatched: null,
      originReportData: {},
      keywordSearchHistoryId: null,
      // 本次交互关键词输入【回车】列表
      ignoreImplicit: true,
      addInputText: '',
      showContent: true,
      recommentActiveWord: [],
      tabList: [{
        key: 'speech',
        tab: '数据',
        originTab: '数据',
        result: 0,
        showContent: true,
        rendered: false
      }, {
        key: 'interaction',
        tab: '互联',
        originTab: '互联',
        result: 0,
        showContent: false,
        rendered: false
      }, {
        key: 'document',
        tab: '文档',
        originTab: '文档',
        result: 0,
        showContent: false,
        rendered: false
      }, {
        key: 'web',
        tab: '网页',
        originTab: '网页',
        result: 0,
        showContent: false,
        rendered: false
      }],
      searchKeyWord: '',
      activeDataRegion: {
        createdBy: 'demo',
        createdDate: '2020-11-02T17:24:06.000+08:00',
        id: '111153445258383360',
        lastModifiedBy: 'anonymousUser',
        lastModifiedDate: '2022-02-01T18:59:52.000+08:00',
        name: '矿业集团'
      } // public-chart页面写死的
    }
  },
  computed: {
    ...mapState({
      activeDataRegionId: state => state.dataRegion.activeItem.id,
      // keyword: state => state.speech.inputKeyword,
      inputStatus: state => state.speech.inputStatus,
      INPUT_STATUS_OPTION: state => state.speech.INPUT_STATUS_OPTION,
      statusBarHeight: state => state.global.statusBarHeight,
      moreHeight: state => state.global.moreHeight,
      searchHistories: state => state.speech.searchHistories,
      historyLocations: state => state.speech.historyLocations
    }),
    keyword: {
      get () {
        return this.$store.state.speech.inputKeyword
      },
      set (val) {
        this.$store.commit('speech/setInputKeyword', val)
      }
    },
    showSearchResult () {
      return this.inputStatus === this.INPUT_STATUS_OPTION.IDLE && this.hasSearchResult && this.showContent
    }
  },
  methods: {
    ...mapActions('speech', [
      'showSpeechModal',
      'hideSpeechModal'
    ]),
    searchItem (history, hasHistory) { // hasHistory 从别处切换回来有历史数据，不显示主页const { searchWords, chartType } = history
      this.$refs['refSearchSpeech'][0].searchItem(history, hasHistory)
    },
    parseKeyword (params) {
      this.searchKeyWord = this.keyword
      this.$refs['refSearchSpeech'][0].keywordSearchHistoryId = this.keywordSearchHistoryId
      this.$refs['refSearchSpeech'][0].parseKeyword(params)
    },
    reset () {
      this.$refs['refSearchSpeech'][0].reset()
    },
    FilterParseKeyword (params) {
      this.parseKeyword(params)
    },
    clickLeftArrow () {
      this.$refs['refSearchSpeech'][0].clickLeftArrow()
    },
    clickRightArrow () {
      this.$refs['refSearchSpeech'][0].clickRightArrow()
    },
    popstateListener(e) {
      const keywordSelectDialog = this.$refs['refKeywordSelectDialog']
      const refSearchInput = this.$refs['refSearchInput']
      const refSpeechView = this.$refs['refSearchSpeech'][0].$refs['speechView'][0]
      const refDocumentPreview = this.$refs['refDocumentPreview']
      // const speechSmartChart = refSpeechView
      const speechSmartChart = refSpeechView?.$refs['speechSmartChart']?.find(item => item.uuid === refSpeechView.activeTabKey)
      if (keywordSelectDialog && keywordSelectDialog.visible) { // 搜索首页关键词选择弹窗
        keywordSelectDialog.visible = false
      } else if (refSearchInput.showRecommended) { // 输入框页面
        refSearchInput.onCancel()
        refSearchInput.$el.getElementsByClassName('van-field__control')[0].blur()
      } else if (refDocumentPreview.active) {
        refDocumentPreview.active = false
      } else if (speechSmartChart) { // 搜索结果页
        const searchComment = this.$refs['searchComment']
        const keywordFilterIcon = speechSmartChart.$refs['keywordFilterIcon']
        const refEarlyWarningDialog = speechSmartChart.$refs['refEarlyWarningDialog']
        const refPredictPopup = speechSmartChart.$refs['refPredictPopup']
        const refBenchmarkingPopup = speechSmartChart.$refs['refBenchmarkingPopup']
        if (speechSmartChart.showAction) { // 操作选项弹窗
          speechSmartChart.showAction = false
        } else if (keywordFilterIcon.showPopup) { // 词分按钮弹窗
          const dtOption = keywordFilterIcon.datetimeOptions.find(dtOption => dtOption.showSelector)
          if (dtOption) {
            dtOption.showSelector = false
          } else if (!history.state.title) {
            keywordFilterIcon.showPopup = false
          }
        } else if (history.state.title === '图表选项') { // 点击操作选项出来的弹窗
          if (refPredictPopup && refPredictPopup.visible) { // 预测弹窗
            refPredictPopup.visible = false
            history.back() // 回退两次包括操作选项的历史
          } else if (refBenchmarkingPopup && refBenchmarkingPopup.visible) { // 对标弹窗
            refBenchmarkingPopup.visible = false
            history.back() // 回退两次包括操作选项的历史
          } else if (refEarlyWarningDialog.visible) { // 通知预警弹窗
            refEarlyWarningDialog.visible = false
            history.back() // 回退操作选项的历史
          }
        } else if (history.state.title === '预警编辑') { // 点击操作选项出来的弹窗
          if (refEarlyWarningDialog.visible) { // 通知预警弹窗
            const refConditionSetting = refEarlyWarningDialog.$refs['refConditionSetting'].$refs['refConditionSettingpopup']
            if (refConditionSetting.showCreateCondition) { // 条件设置弹窗
              refConditionSetting.showCreateCondition = false
            }
          }
        } else if (searchComment.showCommentDialog) { // 意见建议弹窗
          searchComment.showCommentDialog = false
        }
      }
    },
    getRecommentActiveWord (recommentActiveWord) {
      this.recommentActiveWord = recommentActiveWord
    },
    onResult (params, key) {
      const result = params[0]
      const keyword = params[1]
      // this.activeTabKey = 'speech'
      if (keyword) this.keyword = keyword
      const tabIndex = this.tabList.findIndex(item => item['key'] === key)
      const tab = this.tabList[tabIndex]
      // tab['tab'] = `${tab['originTab']}（${result.length}）`
      tab['result'] = result ? result.length : 0
      // tab['showContent'] = false
      tab['rendered'] = true
      // this.resultTabCount += 1
      // 结果数量大于等于总tab时，决定跳转到哪一个tab
      // if (this.resultTabCount >= this.tabList.length) {
      if (this.tabList.findIndex(item => item['rendered'] === false) === -1) {
        this.tabList.forEach(item => {
          item['showContent'] = false
        })
        // if (this.resultTabCount >= this.tabList.length) {
        // this.resultTabCount = 0
        const activeTab = this.tabList.find(item => item['result'] > 0)
        if (activeTab) {
          this.hasSearchResult = true
          activeTab['showContent'] = true
        }
        this.$nextTick(() => {
          this.$refs.my_scroller?.scrollTo(0, 0)
        })
      }
    },
    showPage (hasSearchResult, showContent) {
      console.log('???')
      if (hasSearchResult || hasSearchResult === false) this.hasSearchResult = hasSearchResult
      if (showContent || showContent === false) this.showContent = showContent
    },
    getChartData (params) {
      if (params.keywordMatched) this.keywordMatched = params.keywordMatched
      if (params.keywordSearchHistoryId) this.keywordSearchHistoryId = params.keywordSearchHistoryId
      if (params.originReportData) this.originReportData = params.originReportData
      if (params.ignoreImplicit || params.ignoreImplicit === false) this.ignoreImplicit = params.ignoreImplicit
    },
    changeSearchWord (searchKeyWord) {
      this.searchKeyWord = searchKeyWord
    },
    onLogout () {
      this.$api.user.logout().then(res => {
        this.$ls.remove('accessToken')
        this.$store.dispatch('user/logout')
        this.$router.replace('/login')
      }).catch(() => {
      })
    }
  },
  mounted () {
    window.addEventListener('popstate', this.popstateListener, false)

    this.keywordSearchHistoryId = this.$route.query.keywordSearchHistoryId
    console.log('keywordSearchHistoryId', this.$route.query.keywordSearchHistoryId)
    this.keyword = decodeURI(this.$route.params.keyword) // 默认为首页时进入直接跳转
    this.showContent = false
    if (!this.activeDataRegionId) {
      this.$store.dispatch('dataRegion/setActiveItem', this.activeDataRegion)
    }
    this.parseKeyword()
  },
  watch: {
    searchKeyWord () {
      this.tabList.forEach(item => {
        item['rendered'] = false
      })
    }
  },
  destroyed() {
    this.$eventBus.$off('SPEECH_VOICE_KEYWORD')
    window.removeEventListener('popstate', this.popstateListener)
    this.onLogout()
  }
}
</script>
<style lang="less" scoped>
  .speech-index {
    position: relative;
    // height: calc(100vh - 50px);
    background-repeat: no-repeat;
    .history-keyword {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 999;
    }
    .loading-center {
      padding: 0.5em;
      background: #fff;
      text-align:center
    }
    .scroller-wrapper {
      // height: calc(100vh - 110px);
      position: relative;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      ::-webkit-scrollbar { width: 0 !important }
      .search-result-wrapper {
        .speech-view-box {
          background: #fff;
          margin: 0 15px 10px;
          border-radius: 10px;
          overflow: hidden;
        }
        .left-arrow, .right-arrow {
          width: 50px;
          height: 60px;
          // background-color: #2a2a2a5e;
          position: fixed;
          top: 230px;
          .van-icon {
            color: #dddddd;
            position: relative;
            top: 15px;
            left: 10px;
            font-size: 30px;
          }
        }
        .left-arrow {
          left: 0px;
        }
        .right-arrow {
          right: 0px;
        }
      }
      .search-result-home, .van-pull-refresh {
        height: 100%;
      }
    }
  }
</style>
<style lang="less">
  .speech-index {
    .search-result-home {
      .van-pull-refresh__head {
        color: #ffffff;
      }
    }

    .scroller-wrapper {
      .search-result-wrapper {
        .pull-to-refresh-layer {
          .text {
            color: #ffffff !important;
          }
          .arrow polygon, .arrow rect {
            fill: #ffffff !important;
          }
        }
        .loading-layer {
          height: 0 !important;
        }
      }
      .search-recommend-content {
        .search-track-list {
          background-size: 100%;
          background-image: url("~@/assets/card-background.png");
          margin: 0 15px 15px;
          // margin: 0 15px 6px;
          border-radius: 10px;
          .card-title {
            height: 35px;
            border: none;
            padding: 8px 15px 6px;
            background-color: transparent;
            .van-cell__title {
              img {
                width: 15px;
                height: 17px;
                vertical-align: text-bottom;
                margin-right: 10px;
              }
              .search-track-title {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #333333;
                text-align: left;
                display: inline-block;
              }
            }
            &::after {
              border: none;
            }
          }

          .card-content {
            padding: 5px 0 15px;
            background-color: transparent;
            .van-swipe {
              .van-swipe-item {
                .cell-box {
                  display: flex;
                  align-items: center;
                  padding: 0 17px;
                  .sort_icon {
                    display: inline-block;
                    margin: 4px 0;
                    width: 15px;
                    height: 15px;
                    background-size: 100% 100%;
                    text-align: center;
                    color: #ffffff;
                    font-size: 14px;
                  }
                  .van-cell {
                    width: calc(100% - 15px);
                    padding: 6px 0;
                    margin-left: 10px;
                    display: inline-block;
                    background-color: transparent;
                    position: relative;
                    .van-cell__right-icon {
                      display: none;
                    }
                  .van-cell__title::after {
                    position: absolute;
                    box-sizing: border-box;
                    content: ' ';
                    pointer-events: none;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    border-bottom: 1px solid #ebedf0;
                    -webkit-transform: scaleY(0.5);
                    transform: scaleY(0.5);
                  }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
